import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const NotFoundPage = () => {
  useEffect(() => {
    navigate('/contact-us/');
  }, []);

  return null;
};

export default NotFoundPage;